import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useStaticQuery, graphql } from "gatsby";

import { Section, Title } from "../../components/Core";
import CaseCard from "../../components/CaseCard";

type Frontmatter = {
  frontmatter: {
    slug: string;
    client: {
      overviewTextPara1: string;
    };
    hero: {
      title: string;
      quote: string;
      listImage: {
        childImageSharp: {
          fluid: {
            src: string | undefined;
          };
        };
      };
      brandImage: {
        childImageSharp: {
          fluid: {
            src: string | undefined;
          };
        };
      };
    };
  };
};

const CaseList2 = () => {
  const data = useStaticQuery(graphql`
    query CaseListWhoPage {
      allMarkdownRemark(
        filter: {
          frontmatter: {
            type: { eq: "case-study" }
            listGroup: { eq: "home page and caseList" }
          }
        }
      ) {
        nodes {
          frontmatter {
            slug
            hero {
              title
              quote
              listImage {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              brandImage {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const result = data.allMarkdownRemark.nodes;

  return (
    <>
      {/* <!-- Feature section --> */}
      <Section className="position-relative ">
        <Container>
          <Row className="align-items-center justify-content-center">
            {result.map((node: Frontmatter) => {
              const { hero, slug } = node.frontmatter;
              console.log(slug);

              return (
                <Col lg="6" className="mb-5 mb-lg-0">
                  <CaseCard
                    img={hero.listImage.childImageSharp.fluid.src}
                    imgBrand={hero.brandImage.childImageSharp.fluid.src}
                    title={hero.title}
                    to={`/case-study/${slug}`}
                  >
                    {hero.quote}
                  </CaseCard>
                </Col>
              );
            })}
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default CaseList2;
