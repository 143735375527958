import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";

import { Title, Button, Section, Box, Text } from "../../components/Core";
import { WhoMarkdownRemark } from "../types/whoWeHelp-markdown";

// @ts-expect-error false positive image import failure
import imgOval from "../../assets/image/png/l1-cta-oval.png";
// @ts-expect-error false positive image import failure
import svgCurve from "../../assets/image/svg/l1-curve-cta.svg";

const LeftCard = styled(Box)`
  position: absolute;
  top: 0;
  left: 0px;
`;

const RightCard = styled(Box)`
  position: absolute;
  top: 0;
  right: -275px;
`;

const CTA: React.FC<WhoMarkdownRemark> = ({ result }) => {
  const { ctaTitle, ctaBtnText, ctaBtnLink } = result;
  return (
    <>
      {/* <!-- CTA section --> */}
      <Section bg="#F7F7FB" className="position-relative" mt={-3}>
        <RightCard>
          <img src={svgCurve} alt="" className="img-fluid" />
        </RightCard>
        <Container>
          <Row className="justify-content-center text-center">
            <Col lg="12">
              <Box mb={5} className="text-center">
                <Title textColor="dark">{ctaTitle} </Title>
              </Box>
              <Box>
                <Link to={ctaBtnLink}>
                  <Button>{ctaBtnText}</Button>
                </Link>
              </Box>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default CTA;
