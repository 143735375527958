import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { WhoMarkdownRemark } from "../types/whoWeHelp-markdown";
import { Title, Section, Box, Text, Button } from "../../components/Core";
import pdfBrochure from "../../assets/downloads/ShopSafe-ShopWatch-and-TownLink-Brochure.pdf";

interface ThemeProps {
  theme: DefaultTheme;
}

const ContentImg = styled(Box)<ThemeProps>`
  box-shadow: ${({ theme }) => `0 42px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  overflow: hidden;
  max-height: 515px;
`;

const Content: React.FC<WhoMarkdownRemark> = ({ result }) => {
  const {
    contentTitle,
    contentText,
    contentBtnText,
    contentBtnTo,
    contentBtnDownload,
  } = result;
  return (
    <>
      <Section>
        <Container>
          <Row>
            <Col lg="6">
              <Title variant="hero">{contentTitle}</Title>
            </Col>
            <Col lg="6" className="pl-lg-5 pt-lg-4">
              <Text>{contentText}</Text>
              <a href={contentBtnDownload} download>
                <Button mt={4} variant="solid" color="light" mr={3}>
                  {contentBtnText}
                </Button>
              </a>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Content;
