import React from "react";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";
import { DefaultTheme } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsersMedical,
  faMobile,
  faClock,
  faGraduationCap,
  faCommentsQuestionCheck,
  faWalkieTalkie,
} from "@fortawesome/pro-light-svg-icons";

import { WhoMarkdownRemark } from "../types/whoWeHelp-markdown";
import { Title, Section, Box, Text } from "../../components/Core";
import { ColorTypes } from "../../utils";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

interface FeatureCardProps {
  color?: string;
  icon?: any;
  title?: string;
  children: React.ReactNode;
}

interface ThemeProps {
  theme: DefaultTheme;
  color: keyof ColorTypes;
  children: React.ReactNode;
}

const FeatureCard: React.FC<FeatureCardProps> = ({
  color = "primary",
  icon,
  title,
  children,
  ...rest
}) => (
  <Box pt="15px" px="30px" borderRadius={10} mb={4} {...rest}>
    <Box
      size={69}
      borderRadius="50%"
      color={color}
      fontSize="28px"
      className="d-flex justify-content-center align-items-center mx-auto"
      css={`
        background-color: ${({ theme, color }: ThemeProps) =>
          rgba(theme.colors[color], 0.1)};
      `}
    >
      <FontAwesomeIcon icon={icon} />
    </Box>
    <div className="text-center">
      <Text
        color="heading"
        as="h3"
        fontSize={4}
        fontWeight={500}
        letterSpacing={-0.75}
        my={3}
      >
        {title}
      </Text>
      <Text fontSize={2} lineHeight={1.75}>
        {children}
      </Text>
    </div>
  </Box>
);

const Feature: React.FC<WhoMarkdownRemark> = ({ result }) => {
  const {
    featureTitle,
    featureSubtitle1,
    featureIcon1,
    featureText1,
    featureSubtitle2,
    featureIcon2,
    featureText2,
    featureSubtitle3,
    featureIcon3,
    featureText3,
    featureSubtitle4,
    featureIcon4,
    featureText4,
    featureSubtitle5,
    featureIcon5,
    featureText5,
    featureSubtitle6,
    featureIcon6,
    featureText6,
  } = result;
  return (
    <>
      {/* <!-- Feature section --> */}
      <Section className="position-relative" mt={-3}>
        <Container>
          <Row className="mb-0 text-center">
            <Col lg="12">
              <Title>{featureTitle}</Title>
            </Col>
          </Row>
          <Row className="align-items-start justify-content-center">
            <Col sm="6" md="6" lg="4" className="mt-3 mt-lg-5 ">
              <FeatureCard
                color="primary"
                icon={featureIcon1}
                title={featureSubtitle1}
              >
                {featureText1}
              </FeatureCard>
            </Col>

            <Col sm="6" md="6" lg="4" className="mt-3 mt-lg-5">
              <FeatureCard
                color="primary"
                icon={featureIcon2}
                title={featureSubtitle2}
              >
                {featureText2}
              </FeatureCard>
            </Col>

            <Col sm="6" md="6" lg="4" className="mt-3 mt-lg-5">
              <FeatureCard
                color="primary"
                icon={featureIcon3}
                title={featureSubtitle3}
              >
                {featureText3}
              </FeatureCard>
            </Col>

            <Col sm="6" md="6" lg="4" className="mt-3 mt-lg-5">
              <FeatureCard
                color="primary"
                icon={featureIcon4}
                title={featureSubtitle4}
              >
                {featureText4}
              </FeatureCard>
            </Col>
            <Col sm="6" md="6" lg="4" className="mt-3 mt-lg-5">
              <FeatureCard
                color="primary"
                icon={featureIcon5}
                title={featureSubtitle5}
              >
                {featureText5}
              </FeatureCard>
            </Col>

            <Col sm="6" md="6" lg="4" className="mt-3 mt-lg-5">
              <FeatureCard
                color="primary"
                icon={featureIcon6}
                title={featureSubtitle6}
              >
                {featureText6}
              </FeatureCard>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Feature;
