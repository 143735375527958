import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { Title, Box, Text } from "../Core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";

const Card = styled(Box)`
  border-radius: 10px 10px;
  overflow: hidden;
`;

const ImageContainer = styled(Box)`
  max-width: 210px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

const BrandImage = styled(Box)`
  overflow: hidden;
  img {
    border-radius: 8px;
    border: 1px solid #eae9f2;
  }
`;

const CardText = styled(Box)`
  padding-left: 30px;
`;

const TitleStyled = styled(Title)`
  transition: 0.3s;
  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

interface Props {
  img: string;
  imgBrand?: string;
  preTitle?: string;
  title: string;
  readMore?: unknown;
  [propName: string]: unknown;
  to: string;
  children: React.ReactNode;
}

const CaseCard: React.FC<Props> = ({
  img,
  imgBrand,
  preTitle,
  title,
  children,
  readMore,
  to,
  ...rest
}) => {
  return (
    <Card className="d-flex" {...rest}>
      <ImageContainer>
        <Link to={to}>
          <img src={img} alt="" className="w-100 img-fluid" />
        </Link>
      </ImageContainer>
      <CardText>
        {imgBrand && (
          <BrandImage mb="16px">
            <img src={imgBrand} alt="" className="img-fluid" />
          </BrandImage>
        )}

        <Link to={to}>
          <TitleStyled variant="card" mb="12px">
            {title}
          </TitleStyled>
        </Link>
        <Text fontSize={2} lineHeight={1.75} mb="0">
          {children}
        </Text>
        <Link to={to}>
          <Text
            textColor="primary"
            fontSize={2}
            lineHeight={1.75}
            fontWeight={700}
            mb="0"
            mt="3"
          >
            Learn More <FontAwesomeIcon icon={faArrowRight} />
          </Text>
        </Link>
      </CardText>
    </Card>
  );
};

export default CaseCard;
