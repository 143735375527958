import React from "react";
import { Helmet } from "react-helmet";
import { graphql, PageProps } from "gatsby";
import { WhoMarkdownRemark } from "../types/whoWeHelp-markdown";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/who-we-help-cms/Hero";
import Content from "../sections/who-we-help-cms/Content";
import CaseList2 from "../sections/who-we-help-cms/CaseList2";
import CTA from "../sections/who-we-help-cms/CTA";
import Feature from "../sections/who-we-help-cms/Feature";

type GraphQLResult = {
  markdownRemark: WhoMarkdownRemark;
};

const About: React.FC<PageProps<GraphQLResult>> = ({ data }) => {
  const result = data.markdownRemark.frontmatter;

  return (
    <>
      <PageWrapper headerDark>
        <Helmet>
          <title>{result.title}</title>
          <meta
            name="description"
            content="A leading supplier of ShopWatch &amp; TownLink radio systems, ShopSafe offer effective and affordable crime reduction radio schemes throughout the UK."
          />
        </Helmet>
        <Hero result={result}></Hero>
        <Content result={result} />
        <CaseList2 />
        <Feature result={result} />
        <CTA result={result} />
      </PageWrapper>
    </>
  );
};
export default About;

export const query = graphql`
  query whoWeHelp($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        heroTitle
        heroBgImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        contentTitle
        contentText
        contentBtnText
        contentBtnTo
        contentBtnDownload
        featureTitle
        featureSubtitle1
        featureIcon1
        featureText1
        featureSubtitle2
        featureIcon2
        featureText2
        featureSubtitle3
        featureIcon3
        featureText3
        featureSubtitle4
        featureIcon4
        featureText4
        featureSubtitle5
        featureIcon5
        featureText5
        featureSubtitle6
        featureIcon6
        featureText6
        ctaTitle
        ctaBtnText
        ctaBtnLink
      }
    }
  }
`;
