import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { background } from "styled-system";
import { WhoMarkdownRemark } from "../types/whoWeHelp-markdown";
import { Title, Section } from "../../components/Core";
import imgCase1 from "../../assets/image/heros/town.png";

const SectionStyled = styled(Section)`
  /* background: url(${imgCase1}) no-repeat center right; */
  background: no-repeat center right;
  background-size: cover;
  background-position: center right;
  position: relative;
  ${background};
`;

const HeroOverlay = styled(Section)`
  background-color: #000;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0.3;
`;

const Hero: React.FC<WhoMarkdownRemark> = ({ result }) => {
  const { heroTitle, heroBgImage } = result;
  return (
    <>
      <SectionStyled
        pt={["110px!important", null, "170px!important"]}
        pb={["50px!important", null, "120px!important"]}
        backgroundImage={`url(${heroBgImage.childImageSharp.fluid.src})`}
      >
        <HeroOverlay />
        <Container>
          <Row>
            <Col lg="6">
              <div>
                <Title variant="hero" color="light">
                  {heroTitle}
                </Title>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};
export default Hero;
